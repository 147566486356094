import React,{useState} from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Config from '../../config/Config';
import useSettings from '../../hooks/useSettings';

const ProceedToPaymentModal = ({ show, handleClose, userDetails, cartItems }) => {
  const { t } = useTranslation();
  const language = 'en'; 
  
    const subTotalPrice = cartItems.reduce((acc, item) => {
      return acc + (item.price * item.quantity);
    }, 0); 

    const { settings, error } = useSettings(language);

   
    if(settings!=""){
    const gstShipping = settings[0].settings
    .filter((setting) => setting.v_variable === "GST" || setting.v_variable === "Shipping")
    .reduce((acc, curr) => {
      acc[curr.v_variable] = curr.v_value;
      return acc;
    }, {});

    var GST = gstShipping.GST;
    var SHIPPING = gstShipping.Shipping;

    }

    SHIPPING = +SHIPPING;
    GST = +GST;

  const TotalGST = (subTotalPrice*GST)/100;
  const TotalPriceGST = subTotalPrice+TotalGST;
  const TotalPrice = SHIPPING+TotalPriceGST




  return (
    <div className='common-drawer-payment'>
      <div className="drawer-content-payment">
        <Modal show={show} onHide={handleClose} size="lg">


        <div className='card-header' style={{zIndex:"1"}}>
            <div className='card-item'>{t('cart_summary')}</div>
            <div className='close-btn'>
              <button className="close-btn-btn" onClick={handleClose}>
              X
              </button>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Products</th>
                  <th scope="col">Title</th>
                  <th scope="col">Price</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((items, index) => {
                  let imageSrc = '';
                  try {
                    imageSrc = JSON.parse(items.images)[0];
                  } catch (e) {
                    imageSrc = items.images;
                  }

                  return (
                    <tr key={index}>
                      <th scope="row">
                        <div className="d-flex align-items-center" style={{width:'83px', height:'81px',border:'1px solid',borderRadius:'9px'}}> 
                          {imageSrc && (
                            <img
                              src={`${Config.apiUrl}${imageSrc}`}
                              alt={items.title}
                              className="cart-item-image"
                              style={{ width: '79px', height: '75px', borderRadius:'9px', padding:'1px 0px 0px 2px' }}
                            />
                          )}
                        </div>
                      </th>
                      <td><p className="mb-0 mt-4">{items.title}</p></td>
                      <td><p className="mb-0 mt-4">{t('price')}: {items.price}</p></td>
                      <td><p className="mb-0 mt-4">{items.quantity}</p></td>
                      <td><p className="mb-0 mt-4">{t('price')}: {items.price * items.quantity}.00</p></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="row g-4 justify-content-end">
            <div className="col-12 col-lg-6">
            <Modal.Body>
              <h2 className="display-7 mb-4">{t('shipping_address_title')}</h2>
              <div className="row g-5">
                <div className="col-md-12">
                  <div className="form-item mb-3">
                    <label className="form-label">{t('name_title')}</label>
                    <Form.Group controlId="formName">
                      <Form.Control type="text" value={userDetails?.name || ''} readOnly />
                    </Form.Group>
                  </div>
                  <div className="form-item mb-3">
                    <label className="form-label">{t('email_title')}</label>
                    <Form.Group controlId="formEmail">
                      <Form.Control type="email" value={userDetails?.email || ''} readOnly />
                    </Form.Group>
                  </div>
                  <div className="form-item mb-3">
                    <label className="form-label">{t('mobile_no_title')}</label>
                    <Form.Group controlId="formMobile">
                      <Form.Control type="text" value={userDetails?.mobile || ''} readOnly />
                    </Form.Group>
                  </div>
                  <div className="form-item mb-3">
                    <label className="form-label">{t('shipping_address_title')}</label>
                    <Form.Group controlId="formAddress">
                      <Form.Control as="textarea" rows={3} value={userDetails?.shipping_address || ''} readOnly />
                    </Form.Group>
                  </div>
                  <div className="form-item mb-3">
                    <label className="form-label">{t('pincode_lable')}</label>
                    <Form.Group controlId="formPincode">
                      <Form.Control type="text" value={userDetails?.pincode || ''} readOnly />
                    </Form.Group>
                  </div>
                </div>
              </div>
              </Modal.Body>
            </div>

            <div className="col-12 col-lg-6">
              <div className="rounded p-4">
                <h2 className="display-7 mb-4">{t('payment_summary')}</h2>
                <div className="d-flex justify-content-between mb-4">
                  <p className="mb-0">Subtotal:</p>
                  <p className="mb-0">{t('price')} {subTotalPrice.toFixed(2)}</p>
                </div>
          
                <div className="d-flex justify-content-between mb-4">
                  <p className="mb-0">GST:</p>
                  <p className="mb-0">{t('price')} {GST.toFixed(2)} %</p>
                </div>

                <div className="d-flex justify-content-between mb-4">
                  <p className="mb-0">Shipping:</p>
                  <p className="mb-0">{t('price')} {SHIPPING.toFixed(2)}</p>
                </div>

                <div className="d-flex justify-content-between py-3 border-top border-bottom">
                  <p className="mb-0">Total:</p>
                  <p className="mb-0">{t('price')} {TotalPrice.toFixed(2)}</p>
                </div>
                <button className="payment_button btn border border-secondary rounded-pill px-5 text-primary" type="button">
                {t('proceed_to_payment')}
                </button>
              </div>
            </div>
          </div>

          <div className="total-price">
             <span>{t('proceed_to_payment')}</span>
          </div>
   
        </Modal>
      </div>
    </div>
  );
};

export default ProceedToPaymentModal;
